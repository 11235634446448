<template>
  <v-container fluid>
    <v-card class="px-6">
      <v-card-title
        primary-title
        class="pb-3"
      >
        <span class="text-h3">{{ labels.title }}</span>
      </v-card-title>
      <v-card-text class="pa-0">
        <div
          class="d-flex flex-column flex-md-row"
          style="gap: 50px"
        >
          <div
            class="flex-grow-1 mt-8"
            style="min-width: 45%"
          >
            <h4 class="mt-2">{{ $t("coupons.settings") }}</h4>
            <div class="">
              <p class="d-flex justify-space-between my-4 mx-4">
                <span class="text-cap italize">{{ labels.code }}</span>
                <span class="text-cap italize">
                  {{ coupon.code }}
                </span>
              </p>
              <hr style="border-top: 1px solid #d3d3d3" />
              <p class="d-flex justify-space-between my-4 mx-4">
                <span>{{ labels.type }}</span>
                <span class="text-cap italize">
                  {{ coupon.type }}
                </span>
              </p>
              <hr style="border-top: 1px solid #d3d3d3" />
              <template v-if="coupon.type === types.flat">
                <p class="d-flex justify-space-between my-4 mx-4">
                  <span>{{ labels.amount }}</span>
                  <span class="text-cap italize">
                    {{ coupon.amount }}
                  </span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
              </template>
              <template v-if="coupon.type === types.percentage">
                <p class="d-flex justify-space-between my-4 mx-4">
                  <span>{{ labels.percentage }}</span>
                  <span class="text-cap italize">
                    {{ coupon.percentage + " %" }}
                  </span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-4 mx-4">
                  <span>{{ labels.max_off }}</span>
                  <span class="text-cap italize">
                    {{ coupon.max_off }}
                  </span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
              </template>
              <p class="d-flex justify-space-between my-4 mx-4">
                <span>{{ labels.target_amount }}</span>
                <span class="text-cap italize">
                  {{ coupon?.target_amount ?? " -" }}
                </span>
              </p>
              <hr style="border-top: 1px solid #d3d3d3" />
              <p class="d-flex justify-space-between my-4 mx-4">
                <span>{{ labels.hub }}</span>
                <span class="text-cap italize">
                  {{ coupon?.hub?.name ?? "-" }}
                </span>
              </p>
              <hr style="border-top: 1px solid #d3d3d3" />
              <p class="d-flex justify-space-between my-4 mx-4">
                <span>{{ labels.max_usage }}</span>
                <span class="text-cap italize">
                  {{ coupon?.max_usage ?? " -" }}
                </span>
              </p>
              <hr style="border-top: 1px solid #d3d3d3" />
              <p class="d-flex justify-space-between my-4 mx-4">
                <span>{{ labels.total_usage }}</span>
                <span class="text-cap italize">
                  {{ coupon?.total_usage ?? " -" }}
                </span>
              </p>
              <hr style="border-top: 1px solid #d3d3d3" />
              <p class="d-flex justify-space-between my-4 mx-4">
                <span>{{ labels.left }}</span>
                <span class="text-cap italize">
                  {{ coupon?.max_usage - coupon?.total_usage ?? 0 }}
                </span>
              </p>
              <hr style="border-top: 1px solid #d3d3d3" />
              <p class="d-flex justify-space-between my-4 mx-4">
                <span>{{ labels.max_usage_per_day }}</span>
                <span class="text-cap italize">
                  {{ coupon?.max_usage_per_day ?? " -" }}
                </span>
              </p>
              <hr style="border-top: 1px solid #d3d3d3" />
              <p class="d-flex justify-space-between my-4 mx-4">
                <span>{{ labels.max_usage_per_user }}</span>
                <span class="text-cap italize">
                  {{ coupon?.max_usage_per_user ?? " -" }}
                </span>
              </p>
              <hr style="border-top: 1px solid #d3d3d3" />
            </div>
          </div>
          <!-- right column -->
          <div
            class="flex-grow-1 mt-8"
            style="min-width: 45%"
          >
            <h4 class="mt-2">{{ labels.validity }}</h4>

            <p class="d-flex justify-space-between my-4 mx-4">
              <span>{{ labels.start_at }}</span>
              <span class="text-cap italize">
                {{ formattedStartAt }}
              </span>
            </p>
            <hr style="border-top: 1px solid #d3d3d3" />
            <p class="d-flex justify-space-between my-4 mx-4">
              <span>{{ labels.end_at }}</span>
              <span class="text-cap italize">
                {{ formattedStartAt }}
              </span>
            </p>
            <hr style="border-top: 1px solid #d3d3d3" />
            <p class="d-flex justify-space-between my-4 mx-4">
              <span>{{ labels.status }}</span>
              <span class="text-cap italize">
                {{ coupon?.status ?? " -" }}
              </span>
            </p>
            <hr style="border-top: 1px solid #d3d3d3" />
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <small>{{ labels.required_field }}</small>
        <v-spacer></v-spacer>
        <v-btn
          depressed
          color="primary"
          @click="returnToList"
          >{{ labels.return }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  computed: {
    ...mapGetters({
      coupon: "coupons/coupon",
    }),
    formattedStartAt() {
      if (this.coupon && this.coupon.start_at) {
        return moment(this.coupon.start_at).format("D/M/YYYY H:mm");
      } else {
        return "-";
      }
    },
    formattedEndAt() {
      if (this.coupon && this.coupon.end_at) {
        return moment(this.coupon.end_at).format("D/M/YYYY H:mm");
      } else {
        return "-";
      }
    },
  },

  data() {
    return {
      types: {
        flat: "flat",
        percentage: "percentage",
      },
      labels: {
        return: this.$t("coupons.return_to_list"),
        title: this.$t("coupons.view"),
        code: this.$t("coupons.code"),
        type: this.$t("coupons.type"),
        hub: this.$t("coupons.hub"),
        percentage: this.$t("coupons.percentage"),
        amount: this.$t("coupons.amount"),
        target_amount: this.$t("coupons.target_amount"),
        max_off: this.$t("coupons.max_off"),
        max_usage: this.$t("coupons.max_usage"),
        max_usage_per_user: this.$t("coupons.max_usage_per_user"),
        max_usage_per_day: this.$t("coupons.max_usage_per_day"),
        total_usage: this.$t("coupons.total_usage"),
        start_at: this.$t("coupons.start_at"),
        end_at: this.$t("coupons.end_at"),
        status: this.$t("coupons.status"),
        left: this.$t("coupons.left"),
        validity: this.$t("coupons.validity"),
      },
    };
  },

  methods: {
    returnToList() {
      this.$router.push("/coupons");
    },
    async loadCoupon() {
      try {
        await this.$store.dispatch("coupons/loadCoupon", this.$route.params.id);
      } catch (error) {
        await this.$store.dispatch(
          "alerts/error",
          error.response?.data?.message
        );
      }
    },
  },
  created() {
    this.loadCoupon();
  },
};
</script>